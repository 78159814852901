.App {
  background-image: url('/bg.png');
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.App .App-gif {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  
}
.App .App-gif img {
  width: 480px;
  height: 268px;
}

.App h1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  color: white;
}